<template>
	<div class="container max">

		<section class="offers content px-0 py-6 mt-6 has-text-left">

			<h1 class="mb-0">Entfalter</h1>
			<h2 class="mt-0 mb-6">Preise und Optionen</h2>

			<div class="columns">
				<div class="column is-3 is-4-tablet is-3-desktop is-hidden-mobile" style="padding: 0;margin-right: -12px;">
					<article class="box">

						<div class="header ">&nbsp;</div>
						<div class="header  sub">&nbsp;</div>

						<div class="contents">
							&nbsp;
						</div>
						<div class="options">
							<ul>
								<li v-for="(opt, index) in offerOptions" :key="`${index}-0`">
									<span :class="index > 5 ? 'has-text-weight-bold' : 'text'">{{ opt.t }}</span>
									<span class="icon is-hidden-mobile" style="width: 1px"><i :class="['jam jam-check round']"/></span>
								</li>
							</ul>
						</div>
						<div class="center cta">
							&nbsp;
						</div>
					</article>
				</div>

				<div class="column is-9">


					<swiper :options="swiperOptions">

						<swiper-slide>
							<article class="box bordered">
								<div class="header basic">Entfalter basic</div>
								<div class="header sub basic">11,99 €</div>
								<div class="contents">
									Angaben zu Aufgaben, Rollen, Welten und dem gewünschten Arbeitsplatz führen zu einem Entfaltungsprofil, einem Entfaltungskonzept und einem Matchingprofil. Der Entfalter basic matcht Dich mit über 200 bestehenden Berufs-, Studien-, Praktikums- und Ehrenamtsprofilen.
									<a href="javascript:void(0)" @click="openModal(0)">&nbsp;Mehr erfahren</a>
								</div>
								<div class="options">
									<ul>
										<li v-for="(opt, index) in offerOptions" :key="`${index}-1`">
											<span class="text is-hidden-tablet is-hidden-desktop">{{ opt.t }}</span>
											<span class="icon is-hidden-mobile"><i :class="['jam jam-check round', opt.in.includes(2) && 'active']"/></span>
										</li>
									</ul>
								</div>
								<div class="center cta">
									<entfalter-button class="responsive" @click="openLink('https://app.entfalter.de/buy/basic')">
										Kaufen
									</entfalter-button>
								</div>
							</article>
						</swiper-slide>

						<swiper-slide>
							<article class="box bordered">
								<div class="header cardgame">
									Entfalter compass
								</div>
								<div class="header sub cardgame">
									24,99 &euro;
								</div>
								<div class="contents">
									Der Entfalter compass verbindet den Entfalter basic als App mit einem komplexen Kartenset. Zusammen können sie zu (D)einem Kompass im Spiel des Lebens werden. Man kann aus den Karten – wie auch im Entfalter basic – individuell ein Profil von sich erstellen und sich mit Referenzprofilen matchen. Wie die 2 weiteren Kartensets im Entfalter compass helfen, im Spiel des Lebens probierend, planend und diskutierend unterwegs zu sein, zeigt sich in ausführlichen Beschreibungen.
									<a href="javascript:void(0)" @click="openModal(3)">&nbsp;Mehr erfahren</a>
								</div>
								<div class="options">
									<ul>
										<li v-for="(opt, index) in offerOptions" :key="`${index}-4`">
											<span class="text is-hidden-tablet is-hidden-desktop">{{ opt.t }}</span>
											<span class="icon is-hidden-mobile"><i :class="['jam jam-check round', opt.in.includes(2) && 'active']"/></span>
										</li>
									</ul>
								</div>
								<div class="center cta">
									<entfalter-button class="responsive" @click="openLink('https://entfalter.de/spiel')">
										Kaufen
									</entfalter-button>
								</div>
							</article>
						</swiper-slide>



						<swiper-slide>
							<article class="box bordered">
								<div class="header group">Entfalter group</div>
								<div class="header sub group">auf Anfrage</div>
								<div class="contents">
									Hier kann man mit vielen Nutzer:innen – beispielsweise als Schulklasse oder in einem Unternehmen – beliebig viele Referenzprofile und Gruppen anlegen. Außerdem kann der Entfalter group Matchings zwischen Gruppenmitgliedern und einzelnen Referenzprofilen zeigen. So lassen sich z.B. Grade der Passung zwischen Stellen und Interessenten darstellen.
									<strong>Entfalter education</strong> für Bildungseinrichtungen<br/>
									<strong>Entfalter business</strong> für Unternehmen<br/>
									<strong>Entfalter non-profit</strong> für Vereine / Gemeinden / NGOs
									<a href="javascript:void(0)" @click="openModal(2)">&nbsp;Mehr erfahren</a>
								</div>
								<div class="options">
									<ul>
										<li v-for="(opt, index) in offerOptions" :key="`${index}-3`">
											<span class="text is-hidden-tablet is-hidden-desktop">{{ opt.t }}</span>
											<span class="icon is-hidden-mobile">
												<i :class="['jam jam-check round', opt.in.includes(4) && 'active']"/>
											</span>
										</li>
									</ul>
								</div>

								<div class="center cta">
									<entfalter-button class="responsive" @click="openLink('https://app.entfalter.de/contact/group')">
										Kontakt
									</entfalter-button>
								</div>
							</article>
						</swiper-slide>

						<swiper-slide>
							<article>

								<div class="contents mb-5">
									Wir bieten außerdem <strong>persönliche Coachings und Workshops</strong> an und unterstützen Sie gerne in Schulen, Organisationen und Unternehmen.
								</div>

								<div class="box bordered">
									<div class="header coaching">Entfalter coaching</div>
									<div class="header sub coaching">auf Anfrage</div>
									<div class="p-3 has-text-left">
										<strong>Persönliche Betreuung</strong>
										<p>Auswertung des persönlichen Entfaltungsprofils und -konzepts sowie der Matchings im Dialog</p>
									</div>
									<div class="options">
									</div>
									<div class="center cta">
										<entfalter-button class="responsive" @click="openLink('https://app.entfalter.de/contact/coaching')">
											Kontakt
										</entfalter-button>
									</div>
								</div>

								<div class="box bordered">
									<div class="header coaching">Entfalter <em>workshop</em></div>
									<div class="header sub coaching">auf Anfrage</div>
									<div class="p-3 has-text-left">
										<strong>Persönliche Betreuung</strong>
										<p> Für Gruppen: Auswertung aller Entfaltungsprofile, -konzepte und Matchings in Entfalter-Workshops</p>
									</div>
									<div class="options">
									</div>
									<div class="center cta">
										<entfalter-button class="responsive" @click="openLink('https://app.entfalter.de/contact/seminar')">
											Kontakt
										</entfalter-button>
									</div>
								</div>
							</article>
						</swiper-slide>

					</swiper>

					<div class="swiper-button-prev is-hidden-mobile is-hidden-fullhd" slot="button-prev"></div>
					<div class="swiper-button-next is-hidden-mobile is-hidden-fullhd" slot="button-next"></div>
					<div class="swiper-pagination is-hidden-desktop" slot="pagination"></div>

				</div>
			</div>
		</section>

		<div v-show="showModal" id="emodal">
			<div class="backdrop" @click="closeModal"></div>
			<div class="content has-text-left">
				<p v-html="modalContent"></p>
				<div class="controls__wrapper">
					<entfalter-button class="responsive" @click="closeModal">Schließen</entfalter-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * Page: Buy page
 *
 * @component
 */
// ID: purchase_units[0].payments.captures[0].id   ===> to server

import EntfalterButton         from "@/components/entfalter-button.component";
import { swiper, swiperSlide } from 'vue-awesome-swiper'
// import 'swiper/swiper.scss';

export default {
	name:       "offers",
	components: {EntfalterButton, swiper, swiperSlide},

	data() {
		return {
			showModal:     false,
			modalContent:  '',
			listOfAds:     [
				{
					id:          1,
					productName: "Entfalter light",
					productSub:  "free",
					cssType:     "light",
				},
				{
					id:          2,
					productName: "Entfalter basic",
					productSub:  "11,99 &euro;",
					cssType:     "basic",
				},
				{
					id:          3,
					productName: "Entfalter plus",
					productSub:  "24,99 &euro;",
					cssType:     "plus",
				},
				{
					id:          4,
					productName: "Entfalter group",
					productSub:  "Auf Anfrage",
					cssType:     "group",
					contents:
					             "<strong>Entfalter education</strong> <br/> für Bildungseinrichtungen<br/><strong>Entfalter business</strong> <br/> für Unternehmen<br/><strong>Entfalter non-profit</strong> <br/> für Vereine / Gemeinden / NGOs",
				},
				{
					id:          5,
					productName: "Entfalter coaching",
					productSub:  "Auf Anfrage",
					cssType:     "coaching",
					contents:
					             "<strong>Persönliche Betreuung</strong><br/><p>Auswertung des persönlichen Entfaltungsprofils und -konzepts sowie der Matchings im Dialog</p>",
				},
				{
					id:          6,
					productName: "Entfalter seminar",
					productSub:  "Auf Anfrage",
					cssType:     "coaching",
					contents:
					             "<strong>Persönliche Betreuung</strong><br/><p> Für Gruppen: Auswertung aller Entfaltungsprofile, -konzepte und Matchings in Entfalter-Seminaren</p>",
				},
			],
			offerOptions:  [],
			swiperOptions: {
				loop:          false,
				slidesPerView: 4,
				spaceBetween:  10,
				breakpoints:   {
					1360: {
						slidesPerView: 2,
						spaceBetween:  5
					},
					768:  {
						slidesPerView: 2,
						spaceBetween:  5
					},
					640:  {
						slidesPerView: 1,
						spaceBetween:  10
					},
				},
				pagination:    {
					el: '.swiper-pagination'
				},
				navigation:    {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			},
			offerDetails:  [
				`Der <strong>Entfalter basic</strong> ist das Herzstück des Entfalters. Hier bearbeitet man die vier Bereiche des Entfalters und bekommt zu jedem Bereich eine auf die eigene Person bezogene Rückmeldung. Im Ergebnis entsteht das individuelle Entfaltungsprofil. Auf dessen Grundlage lässt sich - qua weiterer Befragung - das persönliche Entfaltungskonzept entwickeln. Das zeigt, welche Entfaltungsideen und -wünsche man hat und wie stark deren Gewicht ist. Aus dem individuellen Entfaltungsprofil und Entfaltungskonzept ergibt sich das persönliche Matchingprofil. Das lässt sich mit mehr als 120, im Entfalter angelegten Grundprofilen von Ausbildungs-, Studien-, Praktikums-/ Ehrenamtsangeboten und 70 konkreten Berufen matchen. So entsteht eine Übersicht dazu, was zur eigenen Person grundsätzlich passen kann und was eher nicht.`,
				`Der <strong>Entfalter plus</strong> lässt mit seiner Bearbeitung ebenfalls ein individuelles Entfaltungsprofil und -konzept sowie das daraus resultierende persönliche Matchingprofil entstehen. Auch er zeigt das Matching zu den insgesamt fast 200, im Entfalter angelegten Profilen von Ausbildungs-, Studien-, Praktikums-/ Ehrenamtsangeboten, etc. an. Außerdem kann man im Entfalter plus nun selbst bis zu 8 Matchingprofile von Ausbildungs-, Studien-, Praktikums-/ Ehrenamtsangeboten oder von konkreten Berufen oder Stellen anlegen, etwa solchen, die einen näher interessieren oder die man genauer kennt. Auch diese lassen sich dann mit den individuellen Profilen matchen, so dass sich ihre Passung zu den Individualprofilen zeigt. Beim Nutzen des Entfalter Plus können bis zu 5 Personen eine kleine Entfaltergruppe bilden und sich die selbst angelegten Profile in den persönlichen Matchings zeigen lassen. So erweitern sich für die 5 Entfaltergruppenmitglieder die 200 Matchingmöglichkeiten des Entfalters um bis zu 40 (8x5) weitere Profile, die in der Gruppe von besonderem Interesse waren und selbst angelegt wurden.`,
				`Der <strong>Entfalter group</strong> bietet die Leistungen des Entfalter plus, erweitert aber die Möglichkeiten der Gruppenbildung und des Gruppenaustauschs vom Entfalter plus auf im Prinzip beliebig große Gruppen. So können die persönlichen Profile der Gruppenmitglieder mit allen in der Gruppe angelegten Matchingprofilen von Ausbildungs-, Studien-, Praktikums- und Ehrenamtsangeboten, aber auch konkreten berufen und Stellen, auf Grade und Qualitäten der Passung hin gematcht werden. Außerdem kann auch umgekehrt gematcht werden, also z.B. das einzelne Profil eines Ausbildungsplatzes oder einer Stelle mit den persönlichen Profilen der Mitglieder einer Entfalter-Group. Dafür gilt allerdings, dass die Gruppenmitglieder dieser Nutzung zustimmen und von ihr beim Anlegen ihrer Profile wissen. Diese Matchingmöglichkeit kann z.B. bei Findungsprozessen und Auswahlverfahren für die Besetzung von Stellen nützlich sein.`,
				`<img class="is-hidden-mobile" src="/img/kartenbox.jpg" width="230" style="float:left;margin-right:1rem;"/> Der <strong>Entfalter compass</strong> verbindet den Entfalter basic als App mit einem <strong>komplexen Kartenset</strong>. Zusammen können sie zu (D)einem Kompass im Spiel des Lebens werden. Man kann aus den Karten – wie auch im Entfalter basic – individuell ein Profil von sich erstellen. Dazu legt man – entsprechend persönlicher Vorlieben, Interessen und Aktionspotenziale – zu den Bereichen Aufgaben, Weltbezüge, Rollen und Arbeitsplätze ein Kartenbild. So entstehen eines oder mehrere persönliche Entfaltungsprofile als Kartenbilder. Die App ermöglicht es nun, sich zum Vergleich oder als Orientierung die Profile von Berufen, Studienangeboten, etc. zeigen zu lassen, und sie mit den Kartenbildern zu vergleichen. Mit dem Kartenbild, das man als Entfaltungsprofil auch in der App angelegt hat, kann man – wie im Entfalter basic, – konkret und vielfältig Matchings durchspielen. Wenn man für mehrere individuelle Entfaltungsprofile die Matchings durchführen will, kann man weitere Nutzungscodes für den Entfalter basic ergänzend kaufen. Wie die 2 weiteren Kartensets im Entfalter compass helfen, im Spiel des Lebens probierend, planend und diskutierend unterwegs zu sein, zeigt sich hier: <a href="/spiel">Entfalter compass</a>`
			]

		};
	},

	mounted() {
		// Get offers
		this.offerOptions = this.$utils.getPricingOptions();
		// Let's use localStorage (again) to check if the demo has been completed or not
		this.demoDone = !!this.$utils.validateLocalStorageData("storedData");
	},

	computed: {
		amount() {
			return (3.3 * this.form.numberOfCodes).toFixed(2);
		},
	},

	methods: {
		back() {
			this.$router.push({name: `Offers`, params: {}}).catch(() => {});
		},
		openLink(to) {
			window.location.href = to
		},
		openModal(withIndex) {
			this.modalContent = this.offerDetails[withIndex]
			this.showModal = true
		},
		closeModal() {
			this.showModal = false
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../scss/variables";

.offers {
	width : 100%;

	// Inner containers
	.box {
		padding         : 0;
		font-size       : 0.86em;
		height          : 100%;
		display         : flex;
		flex-direction  : column;
		justify-content : flex-end;
		align-items     : stretch;
		background      : white;
		box-shadow      : none;
		border-radius   : 1em;
		overflow        : hidden;

		&.bordered {
			border : 1px solid;
			}

		// >>>> Flex vertical containers:
		// Head
		.header {
			flex        : 1 0;
			padding     : 10px;
			font-family : $main-font-bold;
			text-align  : left;
			font-size   : 14pt;
			font-weight : bold;
			color       : white;
			line-height : 1;

			&.sub {
				flex          : 1 0;
				font-family   : $main-font;
				display       : block;
				text-align    : right;
				border-radius : 0;
				}
			}

		// Contents box
		.contents {
			padding     : 10px;
			flex        : 1;
			flex-shrink : 0;
			font-size   : 12px;
			overflow-y  : auto;
			@include tablets {
				flex        : 1;
				flex-shrink : 0;
				flex-basis  : 280px;
				}
			@include desktop {
				flex        : 1;
				flex-shrink : 0;
				flex-basis  : 310px;
				}
			}

		// Button section with a bit of margin
		.cta {
			flex : 1 0 80px;
			}
		}

	/// << end flex vertical box


	// General List
	ul {
		margin     : 0;
		list-style : none;

		li {
			display     : block;
			position    : relative;
			line-height : 1.2;
			font-weight : bold;
			padding     : 4px 10px;


			&:nth-child(odd) {
				background : rgba(177, 215, 239, 0.49);
				}

			// ----------
			// Not available: hide all spans
			span {
				text-align : center;

				b {
					font-family : $main-font-bold;
					}
				}

			}

		}

	// Colors
	.cardgame {
		background-color : entfalter-color(aufgaben, base);

		&.sub {
			background-color : entfalter-color(aufgaben, light);
			}
		}

	.basic {
		background-color : #5babe0;

		&.sub {
			background-color : #6baeda;
			}
		}

	.plus {
		background-color : #4387b5;

		&.sub {
			background-color : #508ab0;
			}
		}

	.group {
		background-color : #465b69;

		&.sub {
			background-color : #4e5d67;
			}
		}

	.coaching {

		background-color : entfalter-color(aufgaben, base); //#fe6633;

		&.sub {
			background-color : entfalter-color(aufgaben, light);
			}
		}


	// Info-Icons
	.icon {
		width      : 100%;
		text-align : center;
		}

	.jam.round {
		opacity       : 0;
		margin        : 0 auto;
		background    : entfalter-color(welten, base);
		border-radius : 50%;
		color         : white;

		&.active {
			opacity : 1;
			}
		}
	}

// Modal
#emodal {
	display         : flex;
	flex-direction  : column;
	align-items     : center;
	justify-content : center;
	position        : fixed;
	top             : 0;
	left            : 0;
	right           : 0;
	bottom          : 0;
	z-index         : 1011;

	.backdrop {
		position   : fixed;
		background : rgba(0, 0, 0, 0.3);
		top        : 0;
		left       : 0;
		right      : 0;
		bottom     : 0;
		z-index    : -1;
		}

	.content {
		max-width     : 62em;
		margin        : 0;
		padding       : 2rem;
		background    : white;
		border-radius : 1em;
		box-shadow    : rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
		font-size     : 12px;
		@include tablets-and-up {
			margin    : 0 3rem;
			font-size : inherit;
			}
		}

	}

</style>
